<template>
    <div class="page-domains-register-domain-bulk-register">
        <div class="row">
            <div class="col-12">
                <app-search-box
                    label="Enter your domain name"
                    button-text="Submit"

                    type="textarea"

                    :loading="loading"

                    @search="onSearch"
                />
            </div>
        </div>
    </div>
</template>

<script>
import appSearchBox from '@/components/app-search-box'

export default {
    components: {
        appSearchBox,
    },

    data() {
        return {
            loading: false,
        }
    },

    methods: {
        onSearch(value) {
            const delay_in_ms = 1000

            this.loading = true

            setTimeout(() => {
                const domains = value
                    .split('\n')
                    .filter(domain => domain.trim().length)
                    .map(domain => domain.trim())
                    .join(',')

                this.$router.push({
                    name: 'domains-register-domain-confirm-registration',
                    query: {
                        domains,
                    }
                })

                this.loading = false
            }, delay_in_ms)
        },
    },
}
</script>

<style lang="scss">
// .page-domains-register-domain-bulk-register {}

@media (max-width: $tablet-size) {
    // .page-domains-register-domain-bulk-register {}
}

@media (max-width: $mobile-size) {
    // .page-domains-register-domain-bulk-register {}
}
</style>